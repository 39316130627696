<template>
  <div class="order"
       v-loading.fullscreen.lock="fullscreenLoading">
    <div class="order_table">
      <el-dialog v-model="showUpdata"
                 title="上传图片"
                 @closed='closeFun'
                 width="30%">
        <div class="updata_button">
          <el-upload class="upload-demo"
                     :action="upUrl"
                     :show-file-list='false'
                     :on-success="handleAvatarSuccess"
                     :before-upload="beforeAvatarUpload"
                     :headers='updataHeader'>
            <el-button size="mini"
                       type="success">上传图片</el-button>

            <template #tip>
              <div class="el-upload__tip">
                jpg/png 文件格式 最大 2MB.
              </div>
            </template>
          </el-upload>
        </div>
        <div class="updata_image">
          <div class="image_item"
               v-for="(item,index) in urlImageArr"
               :key="item">
            <div class="del_button"
                 @click="delImage(index)">X</div>
            <el-image :preview-src-list="[item.url]"
                      style="width: 140px; height: 140px"
                      :initial-index='index'
                      :src="item.url">
            </el-image>
          </div>

        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button size="mini"
                       @click="showUpdata = false">关闭</el-button>
            <el-button size="mini"
                       type="primary"
                       @click="saveImage">保存</el-button>
          </span>
        </template>
      </el-dialog>

      <el-dialog title="详情"
                 v-model="detailsShow"
                 width="1000px">

        <el-descriptions direction="vertical"
                         title="地址信息"
                         :column="6"
                         border>
          <el-descriptions-item label="寄件人姓名">
            {{showOrderDetail.jjname||'未选择报价'}}
          </el-descriptions-item>
          <el-descriptions-item label="寄件人电话">
            <span v-if="showOrderDetail.jjmobile">{{showOrderDetail.jjmobile}}</span>
            <span v-else
                  style="color:#999">选择报价后生成</span>
          </el-descriptions-item>

          <el-descriptions-item label="寄件详细地址">{{showOrderDetail.jjprovince+' '+showOrderDetail.jjcity+showOrderDetail.jjdistrict+' '+showOrderDetail.jjaddress}}</el-descriptions-item>

          <el-descriptions-item label="邮政编码">
            <span v-if="showOrderDetail.jjzipcode">{{showOrderDetail.jjzipcode}}</span>
            <span v-else
                  style="color:#999">未填写</span>
          </el-descriptions-item>

          <el-descriptions-item label="公司名">
            <span v-if="showOrderDetail.jjcompanyname">{{showOrderDetail.jjcompanyname}}</span>
            <span v-else
                  style="color:#999">未填写</span>
          </el-descriptions-item>

          <el-descriptions-item label="税号（ EORI ）">
            <span v-if="showOrderDetail.jjcompanytaxid">{{showOrderDetail.jjcompanytaxid}}</span>
            <span v-else
                  style="color:#999">未填写</span>
          </el-descriptions-item>

          <el-descriptions-item label="收件人姓名 | 代码">
            {{showOrderDetail.sjname||'未选择报价'}}
          </el-descriptions-item>
          <el-descriptions-item label="收件人电话">
            <span v-if="showOrderDetail.sjmobile">{{showOrderDetail.sjmobile}}</span>
            <span v-else
                  style="color:#999">用户未选择 | FBA无需电话</span>
          </el-descriptions-item>

          <el-descriptions-item label="收件详细地址">
            {{`${showOrderDetail.gjname||''} ${showOrderDetail.sjprovince||''} ${showOrderDetail.sjcity||''} ${showOrderDetail.sjdistrict||''} ${showOrderDetail.sjaddress||''}`}}
          </el-descriptions-item>

          <el-descriptions-item label="邮政编码">
            <span v-if="showOrderDetail.sjzipcode">{{showOrderDetail.sjzipcode}}</span>
            <span v-else
                  style="color:#999">未填写</span>
          </el-descriptions-item>

          <el-descriptions-item label="公司名">
            <span v-if="showOrderDetail.sjcompanyname">{{showOrderDetail.sjcompanyname}}</span>
            <span v-else
                  style="color:#999">未填写</span>
          </el-descriptions-item>

          <el-descriptions-item label="税号（ EORI ）">
            <span v-if="showOrderDetail.sjcompanytaxid">{{showOrderDetail.sjcompanytaxid}}</span>
            <span v-else
                  style="color:#999">未填写</span>
          </el-descriptions-item>
        </el-descriptions>

        <el-descriptions direction="vertical"
                         title="订单信息"
                         :column="6"
                         border>

          <el-descriptions-item label="订单ID">
            <span>{{showOrderDetail.id}}</span>
          </el-descriptions-item>

          <el-descriptions-item label="订单类型">
            <!-- <span>{{showOrderDetail.type==1?'商业件':'集运件'}}</span> -->
          </el-descriptions-item>

          <el-descriptions-item label="送件类型">
            <span>{{showOrderDetail.fromtype==1?'自寄':showOrderDetail.fromtype==2?'上门取件':'自送仓库'}}</span>
          </el-descriptions-item>

          <el-descriptions-item label="物流单号">
            <span v-if="showOrderDetail.wlnum">{{showOrderDetail.wlnum}}</span>
            <span v-else
                  style="color:#999">集运订单选择报价后生成</span>
          </el-descriptions-item>

          <el-descriptions-item label="快递单号">
            <span v-if="showOrderDetail.kdnum">{{showOrderDetail.kdnum}}</span>
            <span v-else
                  style="color:#999">只有自寄商业与未打包集运才有</span>
          </el-descriptions-item>

          <el-descriptions-item label="转运单号">
            <span v-if="showOrderDetail.zynum!=0">{{showOrderDetail.zynum}}</span>
            <span v-else
                  style="color:#999">后台添加后显示</span>
          </el-descriptions-item>

          <el-descriptions-item label="包裹总体积">
            <span v-if="showOrderDetail.tj">{{showOrderDetail.tj}} 立方厘米</span>
            <span v-else
                  style="color:#999">待测量</span>
          </el-descriptions-item>

          <el-descriptions-item label="包裹总重量">
            <span v-if="showOrderDetail.weight">{{showOrderDetail.weight}} KG</span>
            <span v-else
                  style="color:#999">待测量</span>
          </el-descriptions-item>

          <el-descriptions-item label="包裹数量">
            <span v-if="showOrderDetail.bgnum">{{showOrderDetail.bgnum}} </span>
            <span v-else
                  style="color:#999">待测量</span>
          </el-descriptions-item>
          <el-descriptions-item label="下单时间">
            <span>{{dateSwitch(showOrderDetail.createtime,'YYYY-MM-DD HH:MIN')}}</span>

          </el-descriptions-item>
        </el-descriptions>

        <el-descriptions direction="vertical"
                         title="上门取件信息"
                         v-if="showOrderDetail.fromtype==2"
                         :column="6"
                         border>
          <el-descriptions-item label="取件时间">
            <span>{{dateSwitch(showOrderDetail.qjstartime,'YYYY-MM-DD')}}</span>
          </el-descriptions-item>

          <el-descriptions-item label="取件位置">
            <span style="color:#999">{{'同寄件人地址'}}</span>
          </el-descriptions-item>

          <el-descriptions-item label="取件备注">
            <span v-if="showOrderDetail.qjbzinfo">{{showOrderDetail.qjbzinfo}}</span>
            <span v-else
                  style="color:#999">用户未填写备注</span>
          </el-descriptions-item>

        </el-descriptions>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="showGoods">物品信息</el-button>
            <el-button @click="detailsShow = false">取 消</el-button>
            <el-button type="primary"
                       @click="detailsShow = false">确 定</el-button>
          </span>
        </template>

      </el-dialog>

      <el-dialog title="物品列表"
                 v-model="dialogTableVisible_2">
        <el-table :data="goodsData"
                  style="width: 100%">
          <el-table-column prop="name"
                           label="物品名"
                           width="100">
          </el-table-column>
          <el-table-column prop="enname"
                           label="英文品名"
                           width="100">
          </el-table-column>
          <el-table-column prop="num"
                           label="物品数量"
                           width="100">
          </el-table-column>
          <el-table-column prop="unitprice"
                           label="物品单价"
                           width="100">
          </el-table-column>
          <el-table-column prop="totalamount"
                           label="货物总价">
          </el-table-column>

        </el-table>
      </el-dialog>

      <el-dialog title="导出选项"
                 v-model="showExportExcel"
                 width="500px"
                 @close='offExportExcel'>
        <div class="exportExcel">
          <div class="export_title">需要导出的内容：</div>
          <div class="exportExcel_option">
            <el-checkbox v-for="(item,index) in showConter"
                         :key="index"
                         v-model="item.show"
                         :label="item.title">{{item.title}}</el-checkbox>
          </div>
          <div class="export_title">其他配置：</div>
          <div class="input_item">
            <div class="input_item_text">打印订单数量 ( 共{{pageN}}条 )</div>
            <el-input-number v-model="pageS"
                             :min="1"
                             :max='pageN'
                             :precision='0'
                             @change="handleChange" />
          </div>
          <div class="input_item">
            <div class="input_item_text">EXCEL文件名称</div>
            <el-input v-model="fileName"
                      placeholder="请输入导出的文件名" />
          </div>

        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="showExportExcel = false"
                       size="small">取消</el-button>
            <el-button type="primary"
                       @click="affirmExportExcel"
                       size="small">导出</el-button>
          </span>
        </template>
      </el-dialog>

      <div class="order_table_btn">
        <div class="button_left">
          <div class="left_item">
            <el-input v-model="inquiryData.uid"
                      size="mini"
                      placeholder="输入UID查询 "
                      @keyup.enter="searchInquery" />
          </div>
          <div class="left_item">
            <el-input v-model="inquiryData.orderid"
                      size="mini"
                      placeholder="输入订单ID查询 "
                      @keyup.enter="searchInquery" />
          </div>
          <div class="left_item">
            <el-input v-model="inquiryData.kdnum"
                      size="mini"
                      placeholder="输入快递单号查询 "
                      @keyup.enter="searchInquery" />
          </div>
        </div>
        <div class="button_right">
          <el-button type="info"
                     size="mini"
                     @click="resetData">重置</el-button>
          <el-button type="success"
                     size="mini"
                     @click="show_exportExcel">导出Excel</el-button>
        </div>
      </div>

      <el-table :data="orderData"
                v-loading="tabLoad"
                id="excels"
                style="width: 100%">

        <el-table-column label="更新时间"
                         width="200">
          <template #default="scope">
            {{dateSwitch((scope.row.edittime==0?scope.row.createtime:scope.row.edittime),'YYYY-MM-DD HH:MIN:SS')}}
          </template>
        </el-table-column>

        <el-table-column prop="id"
                         label="订单ID"
                         width="100"
                         v-if="showConter.orderid.show">
        </el-table-column>

        <el-table-column prop="uid"
                         label="UID"
                         v-if="showConter.uid.show"
                         width="100">
        </el-table-column>

        <el-table-column label="物流号"
                         v-if="showConter.wlnum.show">
          <template #default="scope">
            <span v-if="scope.row.wlnum==0"
                  style="color:#999">选报价后生成</span>
            <span v-else
                  class="iscopy"
                  :class="'copy'+ scope.row.id"
                  @click="copy(scope.row.id)"
                  :data-clipboard-text="scope.row.wlnum">{{scope.row.wlnum}}</span>
          </template>
        </el-table-column>

        <el-table-column label="快递单号"
                         width="200"
                         v-if="showConter.kdnum.show">
          <template #default="scope">
            <span v-if="scope.row.kdnum==0"
                  style="color:#999">只有自寄和打包前有</span>
            <span v-else
                  class="iscopy"
                  :class="'copy'+ scope.row.id"
                  @click="copy(scope.row.id)"
                  :data-clipboard-text="scope.row.kdnum">{{scope.row.kdnum}}</span>
          </template>
        </el-table-column>

        <el-table-column label="货架码"
                         width="140"
                         v-if="showConter.hjcode.show"
                         show-overflow-tooltip>
          <template #default="scope">
            <span :data-clipboard-text="hjCodeText(scope.row.zxdinfo)"
                  :class="'copy'+ scope.row.id+'2'"
                  @click="copy(scope.row.id+'2')">
              {{hjCodeText(scope.row.zxdinfo)}}
            </span>
          </template>
        </el-table-column>

        <el-table-column label="订单类型"
                         v-if="showConter.orderType.show"
                         width="130">
          <template #header>
            <el-dropdown>
              <span class="el-dropdown-link">
                订单类型<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :class="orderType==''?'activeDropdown':''"
                                    @click="tabOrderType('')">全部</el-dropdown-item>
                  <el-dropdown-item :class="orderType=='1'?'activeDropdown':''"
                                    @click="tabOrderType('1')">商业件</el-dropdown-item>
                  <el-dropdown-item :class="orderType=='2'?'activeDropdown':''"
                                    @click="tabOrderType('2')">集运</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
          <template #default="scope">
            <span>{{ scope.row.type==1?'商业' :'集运'}}</span>
          </template>
        </el-table-column>

        <el-table-column label="国家"
                         v-if="showConter.state.show"
                         >
          <template #default='scope'>
            <span v-if="scope.row.gjname">{{scope.row.gjname}}</span>
            <span v-else
                  style="color:#999">{{'未选择'}}</span>
          </template>
        </el-table-column>

        <el-table-column label="用户名"
                         v-if="showConter.userName.show"
                        >
          <template #default='scope'>
            <span v-if="scope.row.jjname">{{scope.row.jjname}}</span>
            <span v-else
                  style="color:#999">{{'未选择'}}</span>
          </template>

        </el-table-column>

        <!-- <el-table-column prop="bgnum"
                         label="包裹数量"
                         width="100">
        </el-table-column> -->

        <el-table-column label="订单状态"
                         v-if="showConter.orderStatus.show"
                         width="120">
          <template #header>
            <el-dropdown>
              <span class="el-dropdown-link">
                订单状态<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :class="orderStatus==item.value?'activeDropdown':''"
                                    @click="tabOrderStatus(item.value)"
                                    v-for="item in inquiryStatus"
                                    :key='item.value'>{{item.label}}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
          <template #default="scope">
            <el-tag :type=" parseInt(scope.row.status,10) <0? 'danger':'primary'"
                    disable-transitions>{{scope.row.zt}}</el-tag>
          </template>
        </el-table-column>

        <el-table-column align="right"
                         v-if="!showExportExcel"
                         width="300">
          <template #header>
            <el-input v-model="searchData"
                      prefix-icon="el-icon-search"
                      size="small"
                      @keyup.enter="searchInquery"
                      placeholder="输入物流单号查询">

            </el-input>
          </template>
          <template #default="scope">

            <el-button size="mini"
                       v-if="scope.row.status>=3&&scope.row.status<=5"
                       type="success"
                       @click="skipInspect(scope.row.id)">核验</el-button>
            <el-button size="mini"
                       @click="showDetails(scope.row)">详情</el-button>
            <el-button size="mini"
                       v-if="scope.row.status==2"
                       type="primary"
                       @click="skipCE(scope.row,1)">入库</el-button>
            <el-button size="mini"
                       v-if="scope.row.status==7"
                       type="primary"
                       @click="skipCE(scope.row.wlnum,2)">出库</el-button>
            <el-button size="mini"
                       @click="showUpdataFun(scope.row)">上传图片</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination background
                     @current-change='currentChange'
                     :total="pageN"
                     :page-size='pageS'
                     small="small"
                     layout="total, sizes, prev, pager, next, jumper"
                     @size-change="pageSizeChange">
      </el-pagination>

    </div>
  </div>
</template>

<script>
import { dateSwitch } from '@/assets/common'
import FileSaver from 'file-saver'
var xlsl_data = require('xlsx');
import Clipboard from 'clipboard'
import { postOrder, saverCredential } from '@/request/api'
export default {
  data () {
    return {
      // excel文件名
      fileName: '',

      // 显示控制
      showConter: {
        orderid: {
          show: true,
          title: '订单ID'
        },
        uid: {
          show: true,
          title: 'UID'
        },
        wlnum: {
          show: true,
          title: '物流单号'
        },
        kdnum: {
          show: true,
          title: '快递单号'
        },
        hjcode: {
          show: true,
          title: '货架号'
        },
        orderType: {
          show: true,
          title: '订单类型'
        },
        state: {
          show: true,
          title: '收件国家'
        },
        userName: {
          show: true,
          title: '用户名'
        }
        ,
        orderStatus: {
          show: true,
          title: '订单状态'
        }
      },
      // 是否显示导出excel
      showExportExcel: false,
      //查询数据
      inquiryData: {
        uid: '',
        orderid: '',
        kdnum: ''
      },
      //上传图片请求头
      updataHeader: {
        XTOKIN: ''
      },
      //压缩质量
      imgQuality: 0.3,
      imageUrl: '',
      xtoken: '',
      //  -------上传的数据-------
      credential: { image: '' },
      //  -------上传/编辑图片的订单ID-------
      orderId: '',
      //  -------删除图片的数组-------
      delArr: [],
      //  -------上传图片的数组-------
      urlImageArr: [],
      //  -------显示上传图片-------
      showUpdata: false,
      //  -------是否显示物品信息-------
      dialogTableVisible_2: false,
      //  -------表格加载-------
      tabLoad: false,
      //  -------全局加载-------
      fullscreenLoading: false,
      //  -------显示订单详情数据-------
      detailsShow: false,
      showOrderDetail: {
      },
      //  -------可查询的状态-------
      inquiryStatus: [
        {
          label: '全部',
          value: ''
        },
        {
          label: '待出库',
          value: '7'
        }, {
          label: '已入库',
          value: '3'
        }, {
          label: '待打包',
          value: '33'
        }, {
          label: '已捡货',
          value: '34'
        }, {
          label: '待认领',
          value: '-5'
        }, {
          label: '已退货',
          value: '-6'
        }, {
          label: '部分物品退货',
          value: '-66'
        },
      ],
      //  -------筛选条件-------
      orderType: '',
      orderStatus: '',
      //  -------搜索条件-------
      searchData: '',
      //  -------页码-------
      page: 1,
      pageS: 10,
      pageN: 0,
      //  -------订单数据-------
      orderData: [],
      //   -------用户选择的仓库ID-------
      wid: '',
    }
  },
  computed: {
    upUrl () {
      if (process.env.NODE_ENV == 'production') {
        return 'https://www.tuanyun99.com/wxoms/app/index.php?c=entry&do=upfile&m=wxl_wms&op=upload&i=2';
      } else if (process.env.NODE_ENV == 'development') {
        return '/api/index.php?c=entry&do=upfile&m=wxl_wms&op=upload&i=2';
      } else {
        return ''
      }

    },
    //   -------物品数据-------
    goodsData () {
      let arr = []
      for (const key in this.showOrderDetail.zxdinfo) {
        if (this.showOrderDetail.zxdinfo[key].wpinfo) {
          arr.push(...this.showOrderDetail.zxdinfo[key].wpinfo)
        }
      }
      return arr
    }
  },
  methods: {

    //确认导出excel
    affirmExportExcel () {
      var xlsxParam = { raw: true }   //这个保证表格只进行解析 不做运算
      const tables = document.querySelector('#excels');
      const tables_book = xlsl_data.utils.table_to_book(tables, xlsxParam);
      const table_write = xlsl_data.write(tables_book, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array',
        ignoreEC: false
      });

      try {
        FileSaver.saveAs(
          new Blob([table_write], {
            type: 'application/octet-stream'
          }),
          (this.fileName || Date.now()) + '.xlsx'
        )
      } catch (e) {
        if (typeof console !== "undefined") {
          console.log(e, table_write);
        }
      }
      return table_write
    },
    // 更改单页显示数量回调
    handleChange () {
      this.warehouseorder()
    },
    // 关闭显示回调
    offExportExcel () {
      this.showConter = {
        orderid: {
          show: true,
          title: '订单ID'
        },
        uid: {
          show: true,
          title: 'UID'
        },
        wlnum: {
          show: true,
          title: '物流单号'
        },
        kdnum: {
          show: true,
          title: '快递单号'
        },
        hjcode: {
          show: true,
          title: '货架号'
        },
        orderType: {
          show: true,
          title: '订单类型'
        },
        state: {
          show: true,
          title: '收件国家'
        },
        userName: {
          show: true,
          title: '用户名'
        }
        ,
        orderStatus: {
          show: true,
          title: '订单状态'
        }
      }
      this.showExportExcel = false
    },
    // excel导出选项显示
    show_exportExcel () {

      this.showConter = {
        orderid: {
          show: false,
          title: '订单ID'
        },
        uid: {
          show: false,
          title: 'UID'
        },
        wlnum: {
          show: false,
          title: '物流单号'
        },
        kdnum: {
          show: false,
          title: '快递单号'
        },
        hjcode: {
          show: false,
          title: '货架号'
        },
        orderType: {
          show: false,
          title: '订单类型'
        },
        state: {
          show: false,
          title: '收件国家'
        },
        userName: {
          show: false,
          title: '用户名'
        }
        ,
        orderStatus: {
          show: false,
          title: '订单状态'
        }
      }
      this.showExportExcel = true
    },
    // bese4转图片
    dataURItoBlob (dataURI, type) {
      var binary = atob(dataURI.split(',')[1]);
      var array = [];
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], { type: type });
    },
    //  ------计算出每一箱的货架码-------
    hjCodeText (item) {
      let text = []
      for (const key in item) {
        text.push(item[key].hjcode)
      }
      if (text.join(',') == '') {
        return '未上货架'
      }
      return text.join(',')
    },
    // 保存图片
    async saveImage () {
      this.credential.image = this.urlImageArr
      const res = await saverCredential({
        ckid: this.wid,
        orderid: this.orderId,
        credential: this.credential || {}
      })
      this.$message(res.message)
      this.showUpdata = false
    },
    // 删除图片
    delImage (i) {
      this.delArr.push(this.urlImageArr.splice(i, 1))
    },
    // 上传成功回调
    handleAvatarSuccess (res) {
      if (res.url) {
        this.urlImageArr.push(res)
      }
    },
    // 上传限制
    async beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        const _this = this
        return new Promise(resolve => {
          const reader = new FileReader()
          const image = new Image()
          image.onload = () => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            let rate = image.width / image.height
            let width = ''
            let height = ''
            if (image.width > 1280 || image.height > 1280) {
              width = rate > 1 ? 1280 : 1280 * rate;
              height = rate < 1 ? 1280 : 1280 / rate;
            }
            canvas.width = width;
            canvas.height = height;
            context.clearRect(0, 0, width, height);
            context.drawImage(image, 0, 0, width, height);
            const dataUrl = canvas.toDataURL(file.type);
            const blobData = _this.dataURItoBlob(dataUrl, file.type);
            resolve(blobData)
          }
          reader.onload = (e => { image.src = e.target.result; });
          reader.readAsDataURL(file);
        })
      }
      return isJPG
    },
    //   -------关闭前回调-------
    closeFun () {
      this.orderId = '';
      this.credential = '';
      this.urlImageArr = [];
    },
    //   -------显示上传地址-------
    showUpdataFun (e) {
      this.orderId = e.id
      this.credential = e.extinfo.credential || {}
      if (e.extinfo.credential && e.extinfo.credential.image) {
        this.urlImageArr = e.extinfo.credential.image
      } else {
        this.urlImageArr = []
      }
      this.showUpdata = true
    },
    //   -------跳转出入库-------
    skipCE (wlnum, type) {
      let num = ''
      if (type == 1) {
        if (wlnum.type == 2) {
          num = wlnum.kdnum
        } else {
          num = wlnum.wlnum
        }
      }
      this.$router.push({
        name: 'ComeOut',
        query: {
          wlnum: type == 1 ? num : wlnum,
          module: type == 1 ? wlnum.type : '',
          type
        }
      })
    },
    //   -------显示物品-------
    showGoods () {
      this.dialogTableVisible_2 = true
    },
    //   时间转换
    dateSwitch,
    //  ------跳转核验-------
    skipInspect (id) {
      this.$confirm('用户对于入库信息有疑问才需要重新核验', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.push({
          name: 'inspect',
          query: {
            id
          }
        })
      }).catch(() => {

      });
    },
    //  ------重置-------
    resetData () {
      this.orderType = ''
      this.orderStatus = ''
      this.searchData = ''
      this.inquiryData = {
        uid: '',
        orderid: ''
      },
        this.page = 1
      this.warehouseorder()

    },
    //  ------点击复制-------
    copy (e) {
      let clipboard = new Clipboard('.copy' + e);
      clipboard.on('success', e => {
        this.$message.success("复制成功 ：" + e.text);
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制
        this.$message.warning("该浏览器不支持自动复制 ：" + e.text);
        // 释放内存
        clipboard.destroy()
      })
    },
    //  ------搜索-------
    searchInquery () {
      this.page = 1
      this.pagorderTypee = ''
      this.orderStatus = ''
      this.warehouseorder()
    },
    //  ------显示详情-------
    showDetails (e) {
      this.showOrderDetail = e
      this.detailsShow = true
    },
    //  ------切换订单状态-------
    tabOrderStatus (e) {
      this.orderStatus = e
      this.page = 1
      this.searchData = ''
      this.warehouseorder()
    },
    //  ------一页显示数量切换-------
    pageSizeChange (e) {
      this.pageS = e
      this.warehouseorder()
    },
    //  ------页码切换-------
    currentChange (e) {
      this.page = e
      this.searchData = ''

      this.warehouseorder()
    },
    //  ------切换订单类型-------
    tabOrderType (e) {
      this.orderType = e
      this.page = 1
      this.searchData = ''

      this.warehouseorder()
    },
    //  -------获取仓库订单列表-------
    async warehouseorder () {
      this.tabLoad = true
      let res = await postOrder({
        op: 'list',
        pd: 'getlist',
        page: this.page,
        pageSize: this.pageS,
        ckid: this.wid,
        type: this.orderType,
        status: this.orderStatus,
        wlnum: this.searchData,
        ...this.inquiryData
      })
      if (res.errno != 0) {
        this.$message.error(res.message)
      } else {
        this.orderData = res.orderlist
        this.pageN = parseInt(res.totalNum, 10)
      }
      this.tabLoad = false
    }
  },
  async created () {
    let wid = JSON.parse(localStorage.getItem('ty_warehouseData'))
    let cklist = JSON.parse(localStorage.getItem('cklist'))
    const XTOKIN = localStorage.getItem('tuangyin_ck_userinfo')
    this.updataHeader.XTOKIN = JSON.parse(XTOKIN).token
    if (wid) {
      this.wid = wid.id
      this.warehouseorder()
    } else {
      if (cklist) {
        this.wid = cklist.cklist[0].id
      } else {
        await this.$store.dispatch('cklist')
        this.wid = this.$store.state.warehouse.cklist[0].id
      }
      this.warehouseorder()
    }
  }
}
</script>

<style lang='scss'>
.activeDropdown {
  color: #4c90fd;
}
.exportExcel {
  .el-input-number__decrease,
  .el-input-number__increase {
    display: none;
  }
  .export_title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .input_item {
    position: relative;
    align-items: center;
    .input_item_text {
      top: 2px;
      left: 10px;
      z-index: 9;
      color: #999;
      font-size: 14px;
      position: absolute;
    }
    .el-input-number {
      width: 100%;
    }
    .el-input__inner {
      color: #0e50bb;
      text-align: left;
      line-height: 30px;
      padding: 20px 0 0 10px;
      height: auto !important;
    }
  }

  .exportExcel_option {
    width: 100%;
    row-gap: 16px;
    display: grid;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    box-sizing: border-box;
    background-color: #f7f7f7;
    grid-template-columns: repeat(4, 1fr);
  }
}
.order {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  .updata_image {
    display: grid;
    grid-template-rows: repeat(auto-fill, 150px);
    grid-template-columns: repeat(3, 33.33%);
  }
  .image_item:hover {
    .del_button {
      display: flex;
    }
  }
  .image_item {
    position: relative;
    .del_button {
      right: 0;
      top: -10px;
      width: 20px;
      height: 20px;
      color: #fff;
      z-index: 9999;
      display: none;
      cursor: pointer;
      position: absolute;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      background-color: #f56c6c;
    }
  }
  .updata_button {
    width: 100%;
    text-align: right;
    padding-bottom: 20px;
  }
  .order_table {
    width: 90%;
    margin-top: 30px;
    .el-descriptions {
      margin-bottom: 30px;
    }
    .order_table_btn {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      .button_left {
        display: flex;
        .left_item {
          margin-right: 20px;
        }
      }
    }
    .el-pagination {
      width: 100%;
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }
    .el-dropdown-link {
      font-weight: 700;
      color: #999;
    }
    .iscopy {
      cursor: pointer;
    }
  }
}
</style>